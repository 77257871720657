require('@fontsource/roboto')
require('@fontsource/noto-sans-jp')

exports.onClientEntry = () => {
  const userLang = navigator.language.slice(0, 2)
  if (typeof window !== 'undefined') {
    if (
      !window.location.pathname.startsWith('/en') &&
      !window.location.pathname.startsWith('/ja')
    ) {
      if (['ja', 'en'].includes(userLang)) {
        window.location.pathname = `/${userLang}${window.location.pathname}`
      } else {
        window.location.pathname = `/en${window.location.pathname}`
      }
    }
  }
}
